<template lang="html">
  <header id="header" data-aos="fade">
    <div class="container-fluid">
      <div class="row">
        <div class="col-3 col-logo">
          <router-link to="/">
            <img src="public/images/logo-border.svg">
          </router-link>
        </div>

        <div class="col col-networks">
          <p>
            <a class="t-150" href=""><i class="fa-brands fa-facebook-f"></i></a>
            <a class="t-150" href=""><i class="fa-brands fa-x-twitter"></i></a>
            <a class="t-150" href=""><i class="fa-brands fa-instagram"></i></a>
          </p>
        </div>

        <div class="col col-menu">
          <a class="t-250 btn-menu" @click="isActive = true;"><i class="fa-solid fa-bars-sort icon"></i> <span>MENÚ</span></a>
        </div>
      </div>
    </div>

    <div class="header-menu" v-bind:class="{ active : isActive }">
      <a class="btn-close-m" @click="isActive = false;"><i class="fa-solid fa-x"></i></a>
      <a class="email" href="mailto:info@ceropesos.org">info@ceropesos.org</a>

      <div class="container">
        <div class="row">
          <div class="col-12 main-column">
            <div class="row">
              <div class="col-lg-4 col-opt">
                <router-link to="">
                  <span class="normal">Investigaciones</span>
                  <span class="hover">Investigaciones</span>
                  <i class="fa-sharp fa-solid fa-period dot"></i>
                </router-link>
              </div>

              <div class="col-lg-4 col-opt">
                <router-link to="">
                  <span class="normal">Blog</span>
                  <span class="hover">Blog</span>
                  <i class="fa-sharp fa-solid fa-period dot"></i>
                </router-link>
              </div>

              <div class="col-lg-4 col-opt">
                <router-link to="">
                  <span class="normal">Newsletter</span>
                  <span class="hover">Newsletter</span>
                  <i class="fa-sharp fa-solid fa-period dot"></i>
                </router-link>
              </div>

              <div class="col-lg-4 col-opt">
                <router-link to="">
                  <span class="normal">Podcast</span>
                  <span class="hover">Podcast</span>
                  <i class="fa-sharp fa-solid fa-period dot"></i>
                </router-link>
              </div>

              <div class="col-lg-4 col-opt">
                <router-link to="">
                  <span class="normal">¿Quiénes somos?</span>
                  <span class="hover">¿Quiénes somos?</span>
                  <i class="fa-sharp fa-solid fa-period dot"></i>
                </router-link>
              </div>

              <div class="col-lg-4 col-opt">
                <router-link to="">
                  <span class="normal">Videos</span>
                  <span class="hover">Videos</span>
                  <i class="fa-sharp fa-solid fa-period dot"></i>
                </router-link>
              </div>

              <div class="col-lg-4 col-opt">
                <router-link to="">
                  <span class="normal">Capítulos</span>
                  <span class="hover">Capítulos</span>
                  <i class="fa-sharp fa-solid fa-period dot"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      isActive: false,
    }
  }
}
</script>
