<template lang="html">
  <footer id="footer">

		<section class="main-section">
			<div class="container" data-aos="fade" data-aos-offset="-100">
				<div class="row">
					<div class="col-md-4 col-email">
            <a href="mailto:info@ceropesos.org">info@ceropesos.org</a><br />
						<a href="mailto:jal@ceropesos.org">jal@ceropesos.org</a><br />
					</div>

					<div class="col-md-4 col-logo">
						<router-link to="/">
							<img src="public/images/logo-border.svg">
						</router-link>
					</div>

					<div class="col-md-4 col-networks">
						<a class="t-150" href=""><i class="fa-brands fa-facebook-f"></i></a>
            <a class="t-150" href=""><i class="fa-brands fa-x-twitter"></i></a>
            <a class="t-150" href=""><i class="fa-brands fa-instagram"></i></a>
					</div>
				</div>
			</div>
		</section>

    <section class="phrase-section">
      <div class="container">
        <p>
          Prometo no aceptar ni dar sobornos. Alentar, capacitar y empoderar a todos los ciudadanos de México para eliminar la corrupción en todos los niveles de la sociedad. Asociación civil sin fines de lucro.
        </p>
      </div>
    </section>

    <section class="menu-section">
      <div class="container">
        <p>
          <router-link to="">QUIÉNES SOMOS</router-link>
          <router-link to="">DIRECTORIO</router-link>
          <router-link to="">TRANSPARENCIA</router-link>
          <router-link to="">CONFORMIDAD DE TOLERANCIA</router-link>
          <router-link to="">DONA A CERO PESOS, A. C.</router-link>
        </p>

        <h6 class="mt-3 mt-sm-2 mt-lg-3 copy">© 2024 CERO PESOS ORGANIZACIÓN NO GUBERNAMENTAL</h6>
      </div>
    </section>

  </footer>
</template>

<script>
export default {
}
</script>
