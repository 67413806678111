<template lang="html">
  <div id="home-page">

    <section class="banners-section" data-aos="fade">
      <swiper class="swiper" :options="bannersOptions">
        <swiper-slide>
          <img class="img-desktop" src="public/images/pages/home/banner-1.jpg">
          <img class="img-mobile" src="public/images/pages/home/banner-1-m.jpg">
        </swiper-slide>
        <swiper-slide>
          <img class="img-desktop" src="public/images/pages/home/banner-2.jpg">
          <img class="img-mobile" src="public/images/pages/home/banner-2-m.jpg">
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </section>

    <section class="posts-section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-sec-title" data-aos="fade">
            <h2><i class="fa-solid fa-square"></i> Próximas publicaciones</h2>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-4 col-image" data-aos="fade-up">
            <img class="a" src="public/images/pages/home/libro.jpg">
            <img class="b" src="public/images/pages/home/libro.jpg">
          </div>

          <div class="col-lg-8 col-text" data-aos="fade-left">
            <h6 class="mb-2 title">Libro: Repensar la corrupción; la diferencia entre la luciérnaga y el relámpago.</h6>

            <p class="mb-2">
              Esta obra nos hace reflexionar del daño que ha ocasionado la corrupción a lo largo del tiempo en la humanidad; aunque pareciera un evento reciente el autor nos hace ver que la corrupción lamentablemente es algo que se ha entrañado desde los orígenes del hombre y, a la vez que no es parte de la naturaleza humana; sino mas bien una debilidad en la cual como otros vicios tenemos que ser fuertes de conducta.
            </p>

            <p class="mb-2">
              Así, una vez que el lector termine la lectura de Repensar la corrupción; la diferencia entre la luciérnaga y el relámpago tendrá que realizar una introspección, es decir, nos orilla a decantarnos (a favor) por lo que es correcto, pues indudablemente la corrupción solamente lleva la descomposición social y, en consecuencia nos constriñe a repudiarla luchando por una sociedad mejor.
            </p>

            <p class="mb-2">
              El fenómeno de la corrupción se analiza en países como Rusia, China, Estados Unidos, Reino Unido y, como inclusive se le ha querido dar un valor matemático por diversos expertos para determinar alguna formula que podría disminuir este flagelo; haciendo ver que es un problema generalizado a nivel mundial que requiere una atención desde la prevención, la conciencia y el posterior combate.
            </p>

            <p class="mb-3">
              De este análisis comparado con otros países con nuestra legislación, podemos tomar como propuesta el billete de valor de cero pesos; instrumento con el cual trataremos a todos aquellos funcionarios o sujetos corruptos con el premio que se merecen.
            </p>

            <h6 class="by mb-2"><small>CONTRAPORTADA:</small> Mtro. Fernando Rafael Ramos Gallardo.</h6>
            <h6 class="author"><small>AUTOR:</small> Efrén Monrreal</h6>
          </div>
        </div>
      </div>
    </section>

    <section class="author-section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-sec-title" data-aos="fade">
            <h2>Efrén Monrreal</h2>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-8 col-text" data-aos="fade-right">
            <p class="mb-3">
              Nació en Guadalajara, México, en 1971, es abogado y contador publico. Estudio en el Centro Universitario de Ciencias Sociales y Humanidades y el Centro Universitario de Ciencias Económico- Administrativas. Es Doctor en estudios fiscales por la Benemérita Universidad de Guadalajara y actualmente es doctorando en Criminología. Es profesor en el doctorado en estudios fiscales del Centro Universitario de Ciencias Económico y Administrativas, la maestría en prevención de lavado de dinero y financiamiento al terrorismo y el diplomado para la preparación de la certificación como oficial de cumplimiento en PLD en el IMPC-CPGJ. Así como en la especialidad en integridad publica y estrategias anticorrupción del ITESO.
            </p>

            <p>
              Recibió el reconocimiento a la trayectoria como jurista por sus aportaciones sustanciales para ejercer y salvaguardar el Estado de derecho en México 2022, el “Profesionista Destacado 2015”, ambos por el Gobierno del Estado de Jalisco, México. El merito académico como “estudiante sobresaliente por el excelente desempeño académico 2012”, el premio “MARIANO OTERO 2011-B”, el JULIO VERNE 2005 y FIL JOVEN 1994, todos estos otorgados por la Benemérita Universidad de Guadalajara. En 2017 fue postulado para integrar la comisión de selección del Sistema Estatal Anticorrupción del Estado de Jalisco.
            </p>
          </div>

          <div class="col-lg-4 col-photo" data-aos="fade-up">
            <img class="a" src="public/images/pages/home/photo.jpg">
            <img class="b" src="public/images/pages/home/photo.jpg">
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      // == Carousels ==
      bannersOptions: {
        effect: 'fade',
        loop: true,

        autoplay: {
          delay: 4500,
          disableOnInteraction: false
        },

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        }
      }
      // == ==
    }
  }
}
</script>
